<template>
  <!-- 外边 -->
  <div class="External">
    <!-- 推荐专家-内容 -->
    <div class="Recommended-experts">
      <!-- 分类-导航条 -->
      <div class="head">
        <!-- 分导航条 -->
        <V-Classification :frame="frame"></V-Classification>

        <!-- 搜索框 -->
        <!-- <div class="search">
               <input type="text" class="search-icon" placeholder="输入专家团队名、团队标签"> 
               <span class="search-bar"></span>
        </div>-->
      </div>
    </div>
    <!-- 加入智能团 -->
    <div class="join">
      <V-Join></V-Join>
    </div>
    <div>
      <!-- 智能团动态 -->
      <div class="Recommended-experts">
        <!-- 左边 -->
        <div class="Recommended-left">
          <div>
            <span class="team"></span>
            <span class="dynamic">分类团队排行</span>
          </div>
          <div class="Details">
            <div class="Details1" v-for="(item, index) in Dynamics" :key="index">
              <V-Details :Dynamic="item.Dynamic"></V-Details>
            </div>
          </div>
        </div>
        <!-- 右边 -->
        <div class="Recommended-right">
          <div class="Recommended-head">
            <span class="adopt"></span>
            <span class="Ranking">团队排行</span>
          </div>
          <div class="Details">
            <div class="Details2" v-for="(item, index) in Rankings" :key="index">
              <V-Ranking :Ranking="item.Ranking" :index="index"></V-Ranking>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐智能团 -->
    <div class="Recommended-experts">
      <!-- 推荐智能团  -->
      <div>
        <span class="team"></span>
        <span class="dynamic">推荐专家</span>
      </div>
      <div class="Recommendations" v-for="(item, index) in Recommendations" :key="index">
        <V-Team :index="index" :Recommendation="item.Recommendation"></V-Team>
      </div>
    </div>
  </div>
</template>

<script>
import { QUERY, QUERYED } from "@/services/dao.js";
import Classification from "@/components/all_questions/problem_classification/index.vue";
import Join from "@/views/wisdom-answer/index/recommended-experts/join-the-smart-group/index.vue";
import Details from "@/views/wisdom-answer/index/recommended-experts/dynamic-details/index.vue";
import Ranking from "@/views/wisdom-answer/index/recommended-experts/team-ranking/index.vue";
import Team from "@/views/wisdom-answer/index/recommended-experts/recommendation-team/index.vue";
export default {
  data() {
    return {
      frame: [],
      Dynamics: [],
      Rankings: [],
      Recommendations: []
    };
  },
  created() {
    ///加载专家分类
    this.head();
    ///加载基本信息
    this.init();
    ///加载团队排行
    this.Rankinged();
    ///加载推荐专家
    this.Recommendationed();
  },
  methods: {
    ///加载分类
    async head() {
      //alert(1)

      //alert(1)
      this.frame.splice(0, this.frame.length);
      let info = await QUERY("post", "", "TYPE {id  type}");
      console.log(info);
      for (let i = 0; i < info.data.TYPE.length; i++) {
        this.frame.push(info.data.TYPE[i].type);
      }
    },
    ////分类排行
    async init() {
      let data = await QUERYED(
        "post",
        "",
        "WDClassifyTeam(limit: 5, distinct_on: teamType) { id  teamType    acceptNum   teamImg}  "
      );
      this.Dynamics.splice(0, this.Dynamics.length);
      for (let i = 0; i < data.data.WDClassifyTeam.length; i++) {
        // console.log(data.data.WDClassifyTeam[i].teamType);
        let info = await QUERYED(
          "post",
          "",
          "  WDClassifyTeam_aggregate(where: {teamType: {_eq: " +
            JSON.stringify(data.data.WDClassifyTeam[i].teamType) +
            "}}) { aggregate {     count  }  }"
        );
        let imge = await QUERYED(
          "post",
          "",
          "  TtTheme(where: {title  : {_eq: " +
            JSON.stringify(data.data.WDClassifyTeam[i].teamType) +
            "}}) {img   }"
        );
        this.Dynamics.push({
          Dynamic: {
            id: data.data.WDClassifyTeam[i].id,
            teamType: data.data.WDClassifyTeam[i].teamType,
            acceptNum: info.data.WDClassifyTeam_aggregate.aggregate.count,
            teamImg: this.api.LoginURL.concat(
              imge.data.TtTheme[0].img
            )
          }
        });
      }
    },


    /////团队排行
    async Rankinged() {
      let data = await QUERY(
        "post",
        "",
        "WDTeamByAccept( limit: 5){  id teamName    acceptNum   teamImg}  "
      );
      this.Rankings.splice(0, this.Rankings.length);
      for (let i = 0; i < data.data.WDTeamByAccept.length; i++) {
        this.Rankings.push({
          Ranking: {
            id: data.data.WDTeamByAccept[i].id,
            teamName: data.data.WDTeamByAccept[i].teamName,
            acceptNum: data.data.WDTeamByAccept[i].acceptNum,
            teamImg: this.api.LoginURL.concat(
              data.data.WDTeamByAccept[i].teamImg
            )
          }
        });
      }
    },
    /////推荐专家
    async Recommendationed() {
      let data = await QUERY(
        "post",
        "",
        "  WDSpecialist( limit: 2) {   id  img  name wdAcceptNum wdAnswerNum   industry  intro  articleNum   level }"
      );
      this.Recommendations.splice(0, this.Recommendations.length);
      for (let i = 0; i < data.data.WDSpecialist.length; i++) {
        //  // 回答个数
        //   let data1 = await QUERYED(
        //     "post",
        //     "",
        //     "   WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " +
        //     data.data.WDSpecialist[i].id
        //         +
        //       "}}) { aggregate {  count    }  }"
        //   );
        this.Recommendations.push({
          Recommendation: {
            id: data.data.WDSpecialist[i].id,
            img: this.api.LoginURL.concat(data.data.WDSpecialist[i].img),
            name: data.data.WDSpecialist[i].name,
            industry: data.data.WDSpecialist[i].industry,
            // articleNum: data1.data.WDUserQuestionAnswerRef_aggregate.aggregate.count,
            articleNum: data.data.WDSpecialist[i].wdAnswerNum,
            level: data.data.WDSpecialist[i].wdAcceptNum,
            intro: data.data.WDSpecialist[i].intro
          }
        });
      }
    }
  },

  components: {
    "V-Classification": Classification,
    "V-Join": Join,
    "V-Details": Details,
    "V-Ranking": Ranking,
    "V-Team": Team
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.External {
  width: 100%;
  /* border: 1px solid red; */
  background-color: white;
}
.Recommended-experts {
  width: 1000px;
  /* display:inline-block; */
  /* padding-top: 60px; */
  margin: auto;
  /* border: 1px solid yellow; */
  /* margin-top: 20px; */
}
.head,
.search {
  width: 80%;
  /* border:  1px solid red; */
  /* border: 1px solid red; */
  margin: auto;
}
.search {
  margin-top: 50px;
  text-align: right;
  padding-right: 30px;
  margin-bottom: 10px;
}
.search-icon {
  width: 250px;
  height: 20px;
  background: rgba(255, 255, 255, 1);
  margin-right: 50px;
  padding: 3px;
  border-radius: 4px;
}
.search-bar {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(/img/icon.127a0002.png);
  background-position-x: 410px;
  background-position-y: -40px;
  vertical-align: middle;
  /* border: 1px solid red; */
  position: relative;
  left: -85px;
}
.join {
  width: 100%;
  /* border: 1px solid yellowgreen; */
  background-image: url("../../../../assets/img/blue.png");
  background-size: 100% 303px;
  height: 303px;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}
.Recommended-left {
  width: 480px;
  /* border: 1px solid red; */
  display: inline-block;
}
.Recommended-head {
  width: 95%;
  height: 36px;
  /* border:1px solid fuchsia; */
  padding-left: 20px;
}
.Recommended-right {
  width: 470px;
  /* border: 1px solid green; */
  display: inline-block;
  vertical-align: top;
  margin-left: 45px;
}
.team {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url("../../../../assets/icon/tuandui2.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  position: relative;
  top: 5px;
  margin-right: 5px;
}
.Ranking,
.dynamic {
  width: 80px;
  height: 36px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 36px;
  /* font-weight: bold; */
}
.Details {
  widows: 400px;
  height: 550px;
  margin-top: 20px;
}
.adopt {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url("../../../../assets/icon/tuandui33.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  position: relative;
  top: 5px;
  margin-right: 5px;
}
.Details1 {
  margin-bottom: 25px;
  width: 100%;
  /* border: 1px solid red; */
}
.Recommendations {
  display: inline-block;
  margin-top: 30px;
  margin-left: 50px;
}
</style>
