<template>
  <!-- 分类内容 -->
  <div class="Classification_content">
    <div class="content">
      <div
        class="frame"
        v-for="(teamType, index) in frame"
        :key="index"
        :class="'frame' + (index + 2)"
      >
        <span
          :class="{ classification: classification == index }"
          @click="selectItem(teamType)"
          >{{ teamType }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classification: -1,
    };
  },
  props: {
    frame: {
      type: Array,
      required: true,
    },
  },

  methods: {
    selectItem(teamType) {
      //alert(this.teamType);
      this.$router.push({
        path: "/team-classification",
        query: {
          teamType: teamType,
        },
      });
    },
  },
};
</script>
<style scoped>
.Classification_content {
  width: 100%;
  text-align: center;
}
.content {
  /* text-align: center; */
  /* border:1px solid red; */
  width: 100%;
  height: 90px;
  display: inline-block;
  padding-top: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: black;
  padding-bottom: 15px;
  /* line-height:35px; */
}
.content > div {
  width: 190px;
  /* height:70px; */
  /* float: left; */
  text-align: center;
  display: inline-block;
  overflow: hidden;
}
.content > div > span {
  display: inline-block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}
.frame2,
.frame3,
.frame4,
.frame6,
.frame7,
.frame8 {
  /* border-right: 0; */
  border-right: 1px dashed #767676;

  /* border: 1px solid red; */
}
.frame {
  margin-top: 5px;
  margin-bottom: 5px;
}
.frame .classification {
  color: #0084ff;
  /* font-weight: bold; */
  cursor: pointer;
}

.main .nav .active {
  color: rgba(0, 132, 255, 1);
}
</style>
