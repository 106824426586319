<template>
<!-- 推荐智能团队 -->
    <div class="content">
                   <!-- 左边 -->
           <div class="Recommended-left">
               <div class="left1">
                   <div class="top">
                       <span class="top1">
                             <el-image class="imageel" :src="Recommendation.img"  @click="see(Recommendation.id)"></el-image>
                       </span>
                       <!-- <Span>{{Recommendation.id}}</Span> -->
                       <span class="top2">
                           <span @click="see(Recommendation.id)" class="top2_">{{Recommendation.name}}</span>
                           <span>{{Recommendation.industry}}</span>
                       </span>
                   </div>
                <div v-if="index==0"  class="center">
                       <span >介绍：</span>
                       <span>{{Recommendation.intro}}</span>
                       <span>总纳数：<span class="num">{{Recommendation.level}}</span>
                             总回答数：<span class="num">{{Recommendation.articleNum}}</span>
                       </span>
                   </div>
                <div  v-if="index==1" class="center1">
                  <span >介绍：</span>
                       <span>{{Recommendation.intro}}</span>
                       <span>总纳数：<span class="num">{{Recommendation.level}}</span>
                             总回答数：<span class="num">{{Recommendation.articleNum}}</span>
                       </span>
                   </div>               
                   <div class="bottom"></div>
               </div>
           </div>
    </div>
</template>
<script>
export default {
          props:{   
  Recommendation:{
            type:Object,
            required:true
        },
        index:{
            type:Number,
            required:true
        }
        },
        methods:{
        //查看团队成员的回答列表
    async see(id){
        //alert(id)
        this.$router.push({
            path:"/answer-list"
            ,query:{
            id:id
            }
        })
        },
   }
}
</script>
<style scoped>
.content{
    width: 100%;
    /* height: 450px; */
    /* border: 1px solid red; */
    display: inline-block;
    margin-top: 20px;
    /* padding-bottom: 30px; */
    margin-bottom: 50px;
}
.Recommended-left{
  position:relative; 
  top: 20px;
  width:348px;
  height:425px;
  background:rgba(255,255,255,1);
  border:0px solid rgba(219,219,219,1);
  box-shadow:0px 2px 13px 0px rgba(87,87,87,0.44);
  /* padding-top: 20px; */
  /* padding-left: 20px; */
  display: inline-block;
  margin-right: 80px;
position: relative;
left: 60px;
}
.left1{
 position:relative;
left:-15px; 
top:-15px;
/* margin-top:-15px;  */
filter:alpha(opacity=50);
width:348px;
height:425px;
background:rgba(255,255,255,1);
border:0px solid rgba(190,190,190,1);
box-shadow:0px 2px 13px 0px rgba(87,87,87,0.44);
display: inline-block;
/* border: 1px solid red; */

}
.top{
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    width: 290px;
    height: 162px;
    /* border: 1px solid blue; */
}
.top1{
    width: 120px;
    /* border: 1px solid red; */
    display: inline-block;
    height: 120px;
}
.top2{
    width: 150px;
    /* border: 1px solid yellow; */
    display: inline-block;
    vertical-align: top;
    padding-top: 20px;
    height: 100px;
    font-size:16px;
    font-family:Microsoft YaHei;
    /* font-weight:400; */
    color:rgba(0,0,0,1);
    padding-left: 10px;

    }
.top2>span{
    width: 150px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
}
.top2_{
   font-weight:bold;
   cursor: pointer;
}
.top2_:hover{
text-decoration: underline;    
}
.center{
    width: 328px;
    height: 140px;
    background:rgba(253,249,225,1);
    /* /* padding-left: 50px; */
    padding-top: 50px; 
    padding-left: 20px;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(0,0,0,1);
    /* border:1px solid red */
    /* margin-right: 100px; */

}
.center1{
    /* text-align: right; */
    width: 328px;
    height: 140px;
    background:rgba(232,242,253,1);
    /* /* padding-left: 50px; */
    padding-top: 50px; 
    padding-left: 20px;
    font-size:14px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(0,0,0,1);
}
.num{
    color: #FD6D05;
    margin-right: 20px;
}
.center>span{
    /* border: 1px solid hotpink; */
    width: 300px;
    height: 20px;
    display: inline-block;
    /* margin-left: 70px; */
    margin-bottom: 10px;
    /* height: 30px;
    line-height: 30px; */
    /* margin: auto; */
    /* text-align: center; */
}
.center1>span{
    /* border: 1px solid hotpink; */
    width: 300px;
    height: 20px;
    display: inline-block;
    /* margin-left: 70px; */
    margin-bottom: 10px;
    /* height: 30px;
    line-height: 30px; */
    /* margin: auto; */
    /* text-align: center; */
}
.bottom{
    width: 100%;
    height: 24px;
    /* border: 1px solid red; */
    background-color:#F0EFED;
}
.imageel{
    /* border:1px solid blue; */
    border-radius: 50%;
    display: inline-block;
    width:90%;
    height:90%;
    cursor: pointer;
}
</style>