<template>
     <!-- 智能团排行 -->
    <div class="detail" >

        <div class="that">
            <!-- 排行 -->
        <span v-if="index==0" class="one">1</span>
        <span v-if="index==1" class="one">2</span>
        <span v-if="index==2" class="one">3</span>
        <span v-if="index==3" class="two">4</span>
        <span v-if="index==4" class="two">5</span>
        <img  class="img" :src="Ranking.teamImg">
        <span class="classification" @click="see(Ranking.id)">{{Ranking.teamName}}</span>
        <span class="right1">
            <span v-if="index<3" class="num">{{Ranking.acceptNum}}</span>
            <span v-if="index>=3" class="nums">{{Ranking.acceptNum}}</span>
           <span class="num1">采纳</span> 
            </span>
        </div>
    </div>
</template>
<script>
import { QUERY } from '../../../../../services/dao'
export default {
       props:{   
  Ranking:{
            type:Object,
            required:true
        },
  index:{
        type:Number,
        required:true
  }
        },
        methods:{
    async see(id){
        this.$router.push({path: "/team-details",query:{
            id:id,
            }
            });
    }
        }
   
}
</script>
<style scoped>
.detail{
    width:100%;
    /* border: 1px solid aqua; */
    display: inline-block;
    /* padding-left: 20px; */
}
.circular{
width:5px;
height:5px;
background:rgba(0,132,255,1);
/* border:1px solid rgba(0,132,255,1); */
border-radius:50%;
display: inline-block;
position: relative;
top:-20px
}
.that{
    width: 90%;
    height: 100px;
    display: inline-block;
    /* border: 1px solid goldenrod; */
    margin-left: 40px;
}
.img{
    width: 67px;
    height: 67px;
    border-radius:50%;
    /* background:rgba(255,144,0,1); */
    padding: 2px;
    border: 0;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    top: 10px;
}
.classification{
width:42%;
/* height:454px; */
font-size:14px;
font-family:Microsoft YaHei;
font-weight:300;
display: inline-block;
color:#676767;
    height: 30px;
    line-height: 30px;
/* line-height:110px; */
/* margin-right: 15px; */
 position: relative;
top:-15px ;
cursor: pointer;
text-align: center;
/* border: 1px solid fuchsia; */
} 
.classification:hover{
    text-decoration: underline;
}
.right1{
    width: 30%;
    height: 30px;
    line-height: 30px;
    /* border: 1px solid red; */
    display: inline-block;
    text-align: center;
    /* float: right; */
     position: relative;
top:-15px ;

}
.num{
font-family:Microsoft YaHei;
font-weight:normal;
color:#FD6E05;
font-size: 16px;
/* margin-left: 50px; */
/* position: relative;
top:-20px; */
/* border: 1px solid red; */
display: inline-block;
text-align: right;
}
.nums{
font-family:Microsoft YaHei;
font-weight:normal;
color:#676767;
font-size: 16px;
/* margin-left: 50px; */
/* position: relative;
top:-20px; */
/* border: 1px solid forestgreen; */
display: inline-block;
text-align: right;
}
.num1{
font-family:Microsoft YaHei;
font-weight:normal;
color:#000000;
font-size: 16px;
text-align: right;
/* position: relative;
top:-20px */
}
.one{
    /* width:12px;
height:454px; */
font-size:18px;
font-family:Microsoft YaHei;
font-weight:800;
font-style:italic;
color:rgba(253,109,5,1);
line-height:100px;
position: relative;
top: -13px;
}
.two{
    font-size:18px;
font-family:Microsoft YaHei;
font-weight:800;
font-style:italic;
color:#676767;
line-height:100px;
position: relative;
top: -13px;
}
</style>