<template>
  <!-- 加入智能团 -->
  <div class="join">
    <div class="wide">
      <!-- <div class="join-icon">
               <span class="team"></span>
               <span class="join-bar">加入智能团</span>
              
           </div> -->
      <div class="establish">
        <span class="plus"></span>
        <span class="establish-bar" @click="add">创建智能团队</span>
      </div>
    </div>
    <div class="long">
      <div class="wide1">
        <span class="existing">
          <span class="grey">现有团队</span>
          <span class="blue">{{ Existing }}</span>
        </span>
        <span class="member">
          <span class="grey">团队成员</span>
          <span class="blue">{{ member }}</span>
        </span>
        <span class="Number">
          <span class="grey">总采纳数</span>
          <span class="blue">{{ adoption }}</span>
        </span>
      </div>
    </div>
    <div class="wide2">
      <span class="words">加入团队！共同开启更多精彩… </span>
      <span class="worded" @click="see">查看团队规则</span>
      <span class="cure"></span>
      <span class="left" @click="see"></span>
    </div>
  </div>
</template>
<script>
import { QUERY, UPDATE, DELETE } from "@/services/dao.js";
export default {
  data() {
    return {
      Existing: 0,
      member: 0,
      adoption: 0,
    };
  },
  props: {},
  created() {
    this.init();
  },
  methods: {
    async add() {
      this.$router.push("/create-a-team");
    },
    async see() {
      this.$router.push("/team-rules");
    },
    ////加载基本信息
    async init() {
      let info = await QUERY(
        "post",
        "",
        "   WDTeamByCreateTime_aggregate { aggregate {   count(columns: id)  sum {   acceptNum   }  }  } "
      );
      let data = await QUERY(
        "post",
        "",
        "   WDTeamMember_aggregate(distinct_on: memberId) {  aggregate {     count (columns: id)    } }"
      );
      //alert(info)
      console.log(info);
      //现有团队
      this.Existing = info.data.WDTeamByCreateTime_aggregate.aggregate.count;
      //alert(data.data.WDTeamMember_aggregate.aggregate.count)
      //  团队成员
      this.member = data.data.WDTeamMember_aggregate.aggregate.count;
      // 总采纳数
      this.adoption =
        info.data.WDTeamByCreateTime_aggregate.aggregate.sum.acceptNum;
    },
  },
};
</script>
<style scoped>
.team {
  width: 25px;
  height: 25px;
  /* background-image: url('../../../../../assets/icon/team1.png');     */
  position: relative;
  top: 8px;
  background-repeat: no-repeat;
  display: inline-block;
  background-color: #0084ff;

  /* margin-left: ; */
}
.plus {
  width: 25px;
  height: 25px;
  background-image: url("../../../../../assets/icon/plus.png");
  background-repeat: no-repeat;
  display: inline-block;
  /* background-color: #0084FF;   */
  /* line-height: 36px; */
  position: relative;
  top: 8px;
  /* left: 10px; */
  margin-right: 20px;
  margin-left: 5px;
}
.join {
  width: 100%;
}
.join-icon {
  width: 152px;
  height: 40px;
  /* line-height: 40px; */
  background: rgba(0, 132, 255, 1);
  text-align: center;
  margin-top: 30px;
}
.join-bar {
  width: 79px;
  height: 17px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(251, 252, 252, 1);
}
.wide {
  width: 800px;
  /* border: 1px solid green; */
  padding-top: 20px;
  margin: auto;
  /* display: inline-block; */
}
.wide1 {
  width: 800px;
  height: 50px;
  margin: auto;
  /* margin-top: 30px; */
}
.existing,
.member,
.Number {
  width: 120px;
  height: 50px;
  line-height: 50px;
  margin-right: 100px;
}
.grey {
  width: 60px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(118, 118, 118, 1);
  line-height: 50px;
  /* border: 1px solid red; */
  display: inline-block;
}
.blue {
  font-size: 10px;
  color: #243fe1;
  width: 60px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 50px;
}
.wide2 {
  width: 800px;
  height: 100px;
  line-height: 100px;
  margin: auto;
  margin-top: 20px;
  /* border: 1px solid yellow; */
}
.long {
  width: 100%;
  height: 50px;
  background-color: white;
  border: 1px solid aqua;
  /* margin-top: 40x; */
}
.words {
  height: 25px;
  display: inline-block;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 36px;
  /* border: 1px solid teal; */
}
.worded {
  height: 25px;
  display: inline-block;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 36px;
  /* border: 1px solid teal; */
  cursor: pointer;
}
.words {
  width: 350px;
  margin-right: 70px;
}
.worded {
  width: 150px;
}
.establish {
  width: 100%;
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.cure {
  display: inline-block;
  height: 69px;
  width: 180px;
  background-image: url("../../../../../assets/icon/curve.png");
  background-repeat: no-repeat;
  /* border: 1px solid red; */
  /* margin-left: 3px; */
  position: relative;
  top: 20px;
}
.left {
  display: inline-block;
  height: 24px;
  width: 24px;
  background-image: url("../../../../../assets/icon/Point left.png");
  background-repeat: no-repeat;
  /* border: 1px solid red; */
  /* margin-left: 3px; */
  position: relative;
  /* top:20px  */
  left: -13px;
  cursor: pointer;
}
.establish-bar {
  width: 110px;
  height: 36px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: white;
  line-height: 36px;
  background-color: #0084ff;
  display: inline-block;
  text-align: center;
  position: relative;
  left: -15px;
  cursor: pointer;
}
</style>
