<template>
     <!-- 智能团动态 -->
    <div class="detail" >

        <!-- <div class="that"> -->
        <!-- 时间 -->
        <span class="imgs"><img  class="img" :src="Dynamic.teamImg"></span>
        <span class="classification" @click="see(Dynamic.teamType)">{{Dynamic.teamType}}</span>
        <span class="num">共创建了{{Dynamic.acceptNum}}团队</span>
        </div>
    <!-- </div> -->
</template>
<script>
export default {
      props:{   
  Dynamic:{
            type:Object,
            required:true
        },
        },
        methods:{
            async see(teamType){
          this.$router.push({path: "/team-classification",query:{
          teamType:teamType
           
            }
            })
            }
        }
}
</script>
<style scoped>
.detail{
    width:100%;
    /* border: 1px solid aqua; */
    display: inline-block;
    /* padding-left: 20px; */
}
.circular{
width:5px;
height:5px;
background:rgba(0,132,255,1);
/* border:1px solid rgba(0,132,255,1); */
border-radius:50%;
display: inline-block;
position: relative;
top:-20px
}
.county{
width:1px;
height:100px;
background:#A0A0A0;
display: inline-block;
position: relative;
 left: -4px;
top: 75px; 
}
.that{
    width: 90%;
    height: 100px;
    display: inline-block;
    /* border: 1px solid goldenrod; */
    margin-left: 20px;
}
.img{
    width: 67px;
    height: 67px;
    border-radius:50%;
    /* background:rgba(255,144,0,1); */
    padding: 2px;
    border: 0;
    margin-left: 10px;
    margin-right: 15px;
        position: relative;
    top: 10px;
    /* border: 1px solid red; */
}
.time{
width:31px;
font-size:14px;
font-family:Microsoft YaHei;
font-weight:normal;
color:rgba(103,103,103,1);
margin-right: 10px;
/* line-height:110px; */
position: relative;
top:-20px;
/* border: 1px solid red; */
}
.classification{
width:30%;
/* height:454px; */
font-size:14px;
font-family:Microsoft YaHei;
font-weight:300;
color:rgba(0,132,255,1);
/* line-height:110px; */
/* margin-right: 15px; */
position: relative;
top:-20px;
/* border: 1px solid red; */
cursor: pointer;
display: inline-block;
text-align: center;
}
.classification:hover{
    text-decoration: underline;
}
.adopt{
width:100px;
font-size:14px;
font-family:Microsoft YaHei;
font-weight:normal;
color:rgba(103,103,103,1);
margin-right: 15px;
position: relative;
top:-20px
}
.num{
    width: 30%;
font-family:Microsoft YaHei;
color:#676767;
font-size: 10px;
position: relative;
top:-20px;
text-align: center;
/* border: 1px solid  red; */
display: inline-block;
}
</style>